<template>
	<div class="tcontainer">
		<div style="margin: 10px;">
			<Calendar @hide="loadLazyData" placeholder="Select Range" v-model="dateRange" selectionMode="range" dateFormat='dd/mm/yy' />
		</div>
		<div class="clearfix ttitle">
			<!-- <button @click="showAddEdit" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button> -->
			<div class="pull-right" style="margin: 5px;">
				Total Amount: &#8377; {{ sums.totalAmount }}
			</div>
			<div class="title">Received</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column field="id" header="Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id'] }}
					</div>
				</template>
			</Column>
			<Column field="center_name" header="Center Name" filterField="center_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Center Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['center_name'] }}
					</div>
				</template>
			</Column>
			<!-- <Column field="center_id" header="Center Id" filterField="center_id" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Center Id"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['center_id']}}
                    </div>
                </template>
            </Column> -->
			<Column field="amount" header="Amount" filterField="amount" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Amount" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['amount'] }}
					</div>
				</template>
			</Column>
			<Column field="is_direct" header="Is Direct" filterField="is_direct" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
							<option value="">Select Is Direct</option>

							<option value="y">Y</option>
							<option value="n">N</option>
						</select>
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['is_direct'] }}
					</div>
				</template>
			</Column>
			<Column field="mode" header="Mode" filterField="mode" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Mode" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['mode'] }}
					</div>
				</template>
			</Column>
			<Column field="transaction_id" header="Transaction Id" filterField="transaction_id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Transaction Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['transaction_id'] }}
					</div>
				</template>
			</Column>
			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<!-- <div class="tdata">
                        {{data['created_at']}}
                    </div> -->
					<div class="tdata" style="width: 65px; margin: auto;">
						{{ format(data['created_at'], "DD/MM/YY h:mmA") }}
					</div>
				</template>
			</Column>
			<!-- <Column header="Options">
                <template #body="{data}">
                    <div class="tdata options">
                        <button class="btn btn-sm sm-btn btn-warning" @click="showAddEdit(data)"><i class="fa fa-edit"></i></button>
                        <button class="btn btn-sm sm-btn btn-danger" @click="editId=data['id']; deleteModal.show();"><i class="fa fa-trash"></i></button>
                    </div>
                </template>
            </Column> -->
		</DataTable>

	</div>
</template>

<script>
import Calendar from 'primevue/calendar';
export default {
	components: {
		Calendar,
	},
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			dateRange: "",
			saving: false,
			lazyParams: {},
			sums: {},
			filters: {
				'id': { value: '', matchMode: 'like' },
				'center_id': { value: '', matchMode: 'like' },
				'center_name': { value: '', matchMode: 'like' },
				'amount': { value: '', matchMode: 'like' },
				'is_direct': { value: '', matchMode: 'like' },
				'mode': { value: '', matchMode: 'like' },
				'transaction_id': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
			},
			fdata: {
				id: '',
				center_id: '',
				amount: '',
				is_direct: 'n',
				mode: '',
				transaction_id: '',
				created_at: '',
			},
			fvalidator: {
				center_id: 'required',
				amount: 'required',
				is_direct: 'required|in:y,n',
				mode: 'required|max:255',
				transaction_id: 'required|max:255',
				created_at: 'required',
			}
		}
	},
	mounted() {
		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();
	},
	methods: {
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}
			params.sums = [['amount', 'totalAmount']]
			params.joins = [
				{
					"fTable": "centers",
					"fKey": "center_id",
					"fField": [["name", "center_name"]]
				},
			]
			if (this.dateRange[0] && this.dateRange[1]) {
				// params.filters['center_payment_history.created_at'] = { value: [this.dateRange[0].toISOString(), this.dateRange[1].toISOString()], matchMode: "between" }
				params.filters['center_payment_history.created_at'] = { value: [this.format(this.dateRange[0], "YYYY-MM-DD")+' 00:00:00', this.format(this.dateRange[1], "YYYY-MM-DD")+" 23:59:59"], matchMode: "between" };
			}

			this.post("admin/load-center_payment_history", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
					this.sums = res.sums;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped></style>